import React, { Component } from "react";

class Header extends Component {
  header = {
    display: "flex",
    listStyle: "none",
    height: '70px',
    backgroundColor:'#d31145',
    justifyContent: 'center',
    alignContent: "center",
    position: "relative",
    margin: 0
  };
  back = {
    position: "absolute",
    alignContent: "center",
    left: "0",
    top: '25%',
    margin: 'auto 0 ',
    paddingLeft: '20px'
  };

  render() {
    return (
      <div style={this.header}>
          <div style={{margin: 'auto 0'}}>
            <img src="./AIA_logo.svg" height='40px' width='40px'/>
          </div>
      </div>
    );
  }
}

export default Header;
