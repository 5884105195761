import React, { Component } from "react";
import { Result } from 'antd';

class NotFound extends Component {
  constructor() {
    super();
  }
  
  render() {
    return (
      <React.Fragment>
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the page you visited does not exist."
        />
      </React.Fragment>
    );
  }
}
export default NotFound;