import { GETMethodAuth, POSTMethodAuth,GETMethodAuthWithParam } from "..";

async function listBankAPI() {
  let listBank = await GETMethodAuth("/api/dl_partner_list");
  return listBank.data;
}

async function generateDeepLinkAPI(body) {
  let generateDeeplink = await POSTMethodAuth("/api/dl_generate_link", body);
  return generateDeeplink.data;
}

async function checkPaymentStatusAPI(DLTransactionID) {
  let checkStatus = await GETMethodAuthWithParam("/api/dl_feedback_status",{"DLTransactionID": DLTransactionID});
  return checkStatus.data;
}


export {
    listBankAPI,
    generateDeepLinkAPI,
    checkPaymentStatusAPI
}