import React, { Component } from "react";
import { Button } from "antd";
import { DatePicker, Form } from "antd";
import { authenticationAPI, validatePolicyNumber  } from "../api/login";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      errorCode: 0,
      loading: false,
      displayPolicyNumber: ""
    };
  }

  componentWillMount() {
    this.renderMyData();
  }
  renderMyData() {
    let policy = new URLSearchParams(this.props.location.search).get("policy");
    policy= policy.replaceAll(' ', '+').replaceAll('\"', '');
    return validatePolicyNumber(policy).then(
      (value)=>{
        if(value != 400) {
          this.setState({displayPolicyNumber: value.data.PolicyNumber})
        } else {
          this.props.history.push(
            {
              pathname: `/notfound`
            });
        }
      }
    );
    
  }

  render() {
    const spacing = {
      margin: 0
    };

    const fontSizeEN = {
      fontFamily: "AIAEverestRegular",
      fontSize: "15px",
      color: "#554344",
      spacing: 0,
    };

    const fontSizeKh = {
      fontFamily: "HANUMAN",
      fontSize: "15px",
      color: "#554344",
      
    };

    const BtnSubmit = {
      height: "60px",
      fontSize: "4vw",
      backgroundColor: "#D31145",
      borderColor: "#D31145",
      fontFamily: "AIAEverestCondensedMedium",
      position: "fixed",
      top: "90%",
      width: "85%",
    };
    
    //Error Message Base ErrorCode on API
    const errorMessages = [
      {
        errorCode: 1,
        messageErrorKh: "សូមបញ្ជូលថ្ងៃខែឆ្នាំកំណើតអ្នក",
        messageErrorEn: "Please enter your data of birth",
      },
      {
        errorCode: 2,
        messageErrorKh:
          "ថ្ងៃខែឆ្នាំកំណើតដែលអ្នកបានបញ្ចូលមិនត្រឹមត្រូវទេ សូមព្យាយាមម្តងទៀត",
        messageErrorEn:
          "The date of birth entered by you does not match our records. Please try again.",
      },
      {
        errorCode: 3,
        messageErrorKh:
          "អ្នកបានព្យាយាមជាច្រើនដងហើយ សូមព្យាយាមម្តងទៀតនៅ30នាទីក្រោយ",
        messageErrorEn:
          "You have had too many incorrect attempts. Please try again after 30 minutes.",
      },
    ];

    const fontENError = {
      fontFamily: "AIAEverestRegular",
      fontSize: "15px",
      color: "#554344",
      color:"red !important",
      margin: 0,
      textAlign : "left"
    };

    const fontKhError = {
      fontFamily: "HANUMAN",
      fontSize: "15px",
      color: "#554344",
      color:"red !important",
      margin: 0,
      textAlign : "left"
    };

    //Error Styles Message
    const errorDiv = this.state.error ? (
      <div className="error">
        <div
          style={{ backgroundColor: "#FBE9EC", display: "flex", color:"red"}}
        >
          <div style={{ margin: "15px" }}>
            <img src="./Error_Icon.svg" height="20px" width="20px" />
          </div>
          <div style={{ margin: "15px", textAlign : "none" }}>
            {this.state.errorCode !== 0 && (
              <>
                <p style={fontKhError}>
                  {
                    errorMessages.find(
                      (item) => item.errorCode === this.state.errorCode
                    ).messageErrorKh
                  }
                  <br />
                </p>
                <p style={fontENError}>
                  {
                    errorMessages.find(
                      (item) => item.errorCode === this.state.errorCode
                    ).messageErrorEn
                  }
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    ) : ( 
      ""
    );

    //on submit form
    const onFinish = async (values) => {
      if (values.dateOfBirth == undefined) {
        return this.setState({
          error: true,
          errorCode: 1,
        });
      }
      this.setState({loading: true})

      let body = {
        Policy: this.state.displayPolicyNumber,
        DOB: values.dateOfBirth.format("YYYY-MM-DD"),
      };

      let login = await authenticationAPI(body);
      if (login.Code != "1") {
        //Login Not Sucess
        if (!login.IsValid) {
          this.setState({ error: true, errorCode: 2, loading:false });
        }
        if (login.IsLocked) {
          this.setState({ error: true, errorCode: 3,loading:false });
        }
      } else {
        //Login Sucess
        this.setState({ error: false, errorCode: 0, loading:false });
        this.props.onLogin(true);
        this.props.history.push(
          {
            pathname: `/detail`
          },
          {
            DLTransactionID: login.DLTransactionID,
            PolicyInformation: login.Data,
          }
        );
      }
    };
    return (
      <div style={{ width: "100%", justifyContent: "center" }}>
        <div>
          <img src="./picture.png" style={{ width: "100%", height: "auto" }} />
        </div>
        <div
          style={{
            textAlign: "justify",
            padding: "30px",
          }}
        >
          <p style={{ ...spacing, ...fontSizeKh }}>
            លេខបណ្ណសន្យារ៉ាប់រង <br />
          </p>
          <p style={{ ...spacing, ...fontSizeEN }}>Policy Number</p>
          <p
            style={{
              margin: 0,
              fontSize: "7vw",
              color: "#554344",
              fontFamily: "AIAEverestCondensedMedium",
              padding: "8px 0px",
            }}> 
            { 
            this.state.displayPolicyNumber&&(
              this.state.displayPolicyNumber.slice(0,9).match(/.{1,3}/g).join(" ")+this.state.displayPolicyNumber[9]  
            )
            }
          </p>
          <p style={{ ...spacing, ...fontSizeKh }}>
            សូមបញ្ចូលថ្ងៃខែឆ្នាំកំណើតរបស់អ្នកដើម្បីបន្ត
            <br />
          </p>
          <p style={{ ...spacing, ...fontSizeEN }}>
            Please enter your <b>date of birth</b> to proceed further.
          </p>
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item name="dateOfBirth" style={{ margin: 0, color:"red" }}>
              <DatePicker
                className={this.state.error ? "on-datepicker-error" : ""}
                style={{
                  width: "100%",
                  margin: "10px 0 10px 0",
                  height: "40px",
                }}
                format={"DD MMM YYYY"}
                placeholder={"DD MM YYYY"}
                size={"middle"}
                inputReadOnly={true}
              />
            </Form.Item>
            {errorDiv}
            <Form.Item>
              <Button type="primary" block htmlType="submit" style={BtnSubmit} loading={this.state.loading} >
                <span style={{ fontFamily: "HANUMANB", fontWeight: "bold" }}>បន្ត</span>
                <br /> 
                <span style={{ fontSize: "18px"}}>CONTINUE</span>
              </Button>              
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}

export default Home;
