import React, { Component } from "react";

class Content extends Component {
    constructor(props) {
        super(props);
    }
  render() {
    const {children} = this.props;
    return (
      <div style={{ width: 'auto', justifyContent: 'center', display: "flex" }}>
         {children}
      </div>
    );
  }
}

export default Content;
