import React, { Component } from "react";
import { Row, Col, Modal, Button } from "antd";

class ErrorPage extends Component {
  constructor() {
    super();
    this.state = {
      isModalVisible: false,
      contactUsPhoneNumber: [],
    };
  }
  render() {
    const fontSizeEN = {
      fontFamily: "AIAEverestRegular",
      fontSize: "15px",
      color: "#554344",
      spacing: 0,
      margin: 0,
      letterSpacing: "-0.1px",
      textAlign: 'left'
    };

    const fontSizeKh = {
      fontFamily: "HANUMAN",
      fontSize: "15px",
      color: "#554344",
      margin: 0
    };

    const contactUsFontKh = {
      fontFamily: "HANUMANB",
      fontSize: "15px",
      color: "#554344",
      margin: 0,
    };

    const contactUsFontEn = {
      fontFamily: "AIAEverestMedium",
      fontSize: "15px",
      color: "#554344",
      padding: 0,
      margin: 0,
      textAlign: 'left'
    };

    const openTelegram = () => {
      return window.open(this.props.location.state.telegramBotURL, "_blank");
    };

    const showModal = () => {
      this.setState({ isModalVisible: true });
    };

    return (
      <React.Fragment>
        <div style={{ width: "100%", justifyContent: "center" }}>
          <div style={{ width: "auto", padding: "30px" }}>
            <div style={{ textAlign: "justify", position: "relative" }}>
              <p
                style={{
                  margin: 0,
                  fontSize: "6vw",
                  color: "#554344",
                  fontFamily: "HANUMANB",
                }}
              >
                មានកំហុសកើតឡើង
              </p>
              <p
                style={{
                  margin: 0,
                  fontSize: "7vw",
                  color: "#554344",
                  fontFamily: "AIAEverestCondensedMedium",
                  paddingBottom: "10px",
                }}
              >
                SOMETHING WENT WRONG
              </p>
              <div
                style={{
                  marginTop: "20px",
                  width: "auto",
                }}
              >
                <p style={fontSizeKh}>
                  យើងខ្ញុំមិនអាចប្រតិបត្តិលើដំណើរការនៃការទូទាត់បុព្វលាភរបស់លោកអ្នកនៅពេលនេះបានទេ
                </p>
                <p style={fontSizeEN}>
                  We could not process your payment at this moment
                </p>
              </div>
              <div
                style={{
                  marginTop: "40px",
                  width: "auto",
                }}
              >
                <p style={fontSizeKh}>
                  សូមព្យាយាមម្តងទៀត ឬ ទូរស័ព្ទមកផ្នែកបម្រើសេវាអតិថិជនរបស់ អេ អាយ
                  អេ​សម្រាប់ជំនួយបន្ថែម។
                </p>
                <p style={fontSizeEN}>
                  Please try again later or contact Customer Service below.
                </p>
              </div>
              <div>
                <div
                  style={{
                    background: "#FFFFFF 0% 0% no-repeat padding-box",
                    boxShadow: "0px 2px 5px #0000002E",
                    borderRadius: "10px",
                    marginTop: "20px",
                    width: "auto",
                    padding: "10px",
                    alignItems: "center",
                  }}
                >
                  <Row align="middle" onClick={openTelegram}>
                    <Col span={6}>
                      <div
                        style={{
                          margin: "0",
                          justifyContent: "left",
                          display: "flex",
                          padding: 0,
                          alignItems: "left",
                        }}
                      >
                        <img
                          src="telegram-logo.png"
                          height="55px"
                          width="55px"
                        />
                      </div>
                    </Col>
                    <Col span={18}>
                      <p style={contactUsFontKh}>
                        ចុះឈ្មោះតេឡេក្រាមរបស់យើងខ្ញុំ
                      </p>
                      <p style={contactUsFontEn}>
                        Register For Our Telegram Service Channel
                      </p>
                    </Col>
                  </Row>
                </div>
                <div
                  style={{
                    background: "#FFFFFF 0% 0% no-repeat padding-box",
                    boxShadow: "0px 2px 5px #0000002E",
                    borderRadius: "10px",
                    marginTop: "20px",
                    width: "auto",
                    padding: "10px",
                    alignItems: "center",
                  }}
                >
                  <Row align="middle" onClick={showModal}>
                    <Col span={6}>
                      <div
                        style={{
                          margin: "0",
                          justifyContent: "left",
                          display: "flex",
                          padding: 0,
                        }}
                      >
                        <img
                          src="callnow-prime1.png"
                          height="55px"
                          width="55px"
                        />
                      </div>
                    </Col>
                    <Col span={18}>
                      <p style={contactUsFontKh}>ទូរស័ព្ទមកយើងខ្ញុំ</p>
                      <p style={contactUsFontEn}>Talk To Us</p>
                      <Modal
                        visible={this.state.isModalVisible}
                        footer={null}
                        closable={false}
                        centered={true}
                        style={{
                          padding:"20px"
                        }}
                      >
                        <p
                          style={{
                            fontFamily: "HANUMAN",
                            fontSize: "14px",
                            color: "#554344",
                            fontWeight: "bold",
                            margin: 0,
                            marginTop: "10px",
                          }}
                        >
                          លេខទំនាក់ទំនង
                        </p>
                        <p
                          style={{
                            fontFamily: "AIAEverestMedium",
                            fontSize: "14px",
                            color: "#554344",
                            fontWeight: "bold",
                            margin: 0,
                            marginBottom: "10px"
                          }}
                        >
                          Hotline
                        </p>
                        {this.props.location.state.phoneNumber.map((item) => {
                          return (
                            <Button block href={"tel:" + item} size="large"
                            style={{
                              fontFamily: "AIAEverestMedium",
                              fontSize: "3.5vw",
                              color: "#554344",
                              fontWeight: "bold",
                              marginTop: "10px",
                              marginBottom: "10px",
                              borderColor:"#B3B1A8",
                              height:"40px",
                              display: "flex",
                              alignItems: "left",
                              justifyItems:"center",
                            }}
                            >
                              <span>{item}</span>
                            </Button>
                          );
                        })}
                      </Modal>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ErrorPage;
