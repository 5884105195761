import React , {Fragment} from "react";
import { Route } from "react-router-dom";
import Detail from "../pages/Detail";
import Header from "../components/header";
import Content from "../components/content";
import Home from "../pages/home";
import ListBank from "../pages/ListBank";
import SucessPage from "../pages/sucess";
import ErrorPage from "../pages/Error";
import NotFound from "../pages/NotFound";

class ReactRouter extends React.Component {
  constructor() {
    super();
    this.state = {
      isAuth: false
    };
  }
  
  render() {
    const onLogin = (param) => {
      if (localStorage.getItem("token")) {
        this.setState({
          isAuth: param
        })
      }
    }
    return (
      <React.Fragment>
        <Header />
        <Content>
        <Route exact path="/" component={(props) => <Home onLogin={onLogin} {...props} />}/>
          {
            this.state.isAuth && (
              <Fragment>
                <Route path="/detail" component={Detail} />
                <Route path="/bank" component={ListBank} />
                <Route path="/sucess" component={SucessPage} />
                <Route path="/error" component={ErrorPage} />
              </Fragment>
            )
          }
          <Route path="/notfound" component={NotFound} />
      </Content> 
      </React.Fragment>
    );
  }
}

export default ReactRouter;
