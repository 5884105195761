import React, { Component } from "react";
import { Avatar, List, Spin } from "antd";
import { generateDeepLinkAPI, checkPaymentStatusAPI } from "../api/listBank";

class ListBank extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      DLTransactionID:"",
      sucess: false
    };
  }
  render() {
    const fontSizeEN = {
      fontFamily: "AIAEverestRegular",
      fontSize: "15px",
      color: "#554344",
      spacing: 0,
      margin: 0,
      letterSpacing: "0.08em",
    };

    const fontSizeKh = {
      fontFamily: "HANUMAN",
      fontSize: "15px",
      color: "#554344",
      margin: 0,
    };
    const data = this.props.location.state.data;

    const btnList = async (values) => {
      const body = {
        DLTransactionID: this.props.location.state.DLTransactionID,
        PartnerUID: values.PartnerUID,
      };
      this.setState({DLTransactionID: body.DLTransactionID})
      //Call API to Generate deeplink
      const generateDeeplink = await generateDeepLinkAPI(body);
      this.setState({ loading: true });
      // return window.open(generateDeeplink.DeepLink, "_blank");
      return window.location.assign(generateDeeplink.DeepLink)
    };

    // Set Timeout for respone sucess or error message page

    // const setTimeoutRequest = async () => {
    //   let transaction = false;
    //   let checkTransaction;
    //   var ti = await setInterval( async () => {
    //     checkTransaction = await checkPaymentStatusAPI(this.state.DLTransactionID); 
    //     if(checkTransaction.Code == "1") {
    //       clearInterval(ti);
    //       transaction = true;
    //       this.props.history.push(
    //       {
    //         pathname: `/sucess`,
    //       }, {
    //         telegramBotURL: checkTransaction.telegramBotLink,
    //         phoneNumber: checkTransaction.contactUsPhoneNumber,
    //         data: checkTransaction.Data
    //       });
    //     }
    //   }, 10000); // set time interal to request api to check payment status (milliseconds)

    //   setTimeout(() => {
    //     clearInterval(ti);
    //     if(!transaction) {
          
    //       this.props.history.push(
    //         {
    //           pathname: `/error`,
    //         }, {
    //           telegramBotURL: checkTransaction.telegramBotLink,
    //           phoneNumber: checkTransaction.contactUsPhoneNumber
    //         });
    //     }
    //   }, 180000); // set timeout to request api to check payment status (milliseconds)
    // }

    // if (this.state.loading) {
    //   setTimeoutRequest()
    //   return (
    //     <div style={{display: "flex", height: "90vh", alignItems:"center"}}>
    //       <div style={{top: "50%", left: "50%"}}>
    //         <Spin size="large"/>
    //       </div>
    //     </div>
    //   );
    // }
    return (
      <React.Fragment>
        <div style={{ width: "100%", justifyContent: "center"}}>
          <div style={{ width: "auto", padding: "30px" }}>
            <div
              style={{
                textAlign: "justify",
                position: "relative",
                paddingBottom: "30px",
              }}
            >
              <p
                style={{
                  margin: 0,
                  fontSize: "5vw",
                  color: "#554344",
                  fontFamily: "HANUMANB",
                  padding: "0px 0px",
                  letterSpacing: "0.02em",
                }}
              >
                ការទូទាត់បុព្វលាភធានារ៉ាប់រង
              </p>
              <p
                style={{
                  margin: 0,
                  fontSize: "7vw",
                  color: "#554344",
                  fontFamily: "AIAEverestCondensedMedium",
                  paddingBottom: "30px",
                }}
              >
                PAYMENT
              </p>
              <p style={fontSizeKh}>សូមជ្រើសរើសជម្រើសនៃការទូទាត់</p>
              <p style={fontSizeEN}>Please select payment option.</p>
            </div>
            <List
              itemLayout="horizontal"
              dataSource={data}
              renderItem={(item) => (
                <List.Item
                  style={{ borderTop: "0.5px solid #B3B1A8" }}
                  actions={[<img src="./Next_Icon.svg" width="8em" />]}
                  onClick={() => btnList(item)}
                >
                  <List.Item.Meta
                    style={{ alignItems: "center" }}
                    avatar={<Avatar size={64} src={`${item.Icon}`} />}
                    title={
                      <>
                        <div>  
                          <p
                            style={{
                              color: "#554344",
                              fontFamily: "HANUMANB",
                              padding: "0",
                              margin: 0,
                              fontSize: "15px",
                              margin: "0.2em",
                            }}
                          >
                            {item.DisplayNameKH}
                          </p>
                          <p
                            style={{
                              color: "#554344",
                              fontFamily: "AIAEverestMedium",
                              padding: 0,
                              margin: "0.2em",
                              fontSize: "15px",
                            }}
                          >
                            {item.DisplayName}
                            <br />
                          </p>
                        </div>
                      </>
                    }
                  />
                </List.Item>
              )}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ListBank;
