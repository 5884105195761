import React, { Component } from "react";
import { Row, Col, Button } from "antd";
import { listBankAPI } from "../api/listBank";
import moment from "moment";
class Detail extends Component {
  render() {
    const data = this.props.location.state;
    const fontSizeEN = {
      fontFamily: "AIAEverestRegular",
      fontSize: "15px",
      color: "#554344",
      spacing: 0,
      margin: 0,
    };

    const fontSizeKh = {
      fontFamily: "HANUMAN",
      fontSize: "15px",
      color: "#554344",
      margin: 0,
    };

    const fontSizeBoldEN = {
      fontFamily: "AIAEverestMedium",
      fontSize: "15px",
      color: "#554344",
      spacing: 0,
      margin: 0
    };

    const spacingRow = {
      margin: "20px 0px 20px 0px",
    };

    const BtnSubmit = {
      height: "60px",
      fontSize: "4vw",
      backgroundColor: "#D31145",
      borderColor: "#D31145",
      fontFamily: "AIAEverestCondensedMedium",
      position: "fixed",
      top: "90%",
      width: "85%",
    };

    const ConfrimBtn = async () => {
      const listBankapi =  await listBankAPI();
      this.props.history.push(
        {
          pathname: `/bank`,
        }, {
        data: listBankapi.Data,
        DLTransactionID: this.props.location.state.DLTransactionID
      });
    };

    return (
      <React.Fragment>
        <div style={{ width: "100%", justifyContent: "center" }}>
          <div style={{ width: "auto", padding: "30px" }}>
            <div style={{ textAlign: "justify", position: "relative" }}>
              <p
                style={{
                  margin: 0,
                  fontSize: "6vw",
                  color: "#554344",
                  fontFamily: "HANUMANB",
                  padding: "0px 0px",
                }}
              >
                ផ្ទៀងផ្ទាត់ព័ត៌មានលម្អិត
              </p>
              <p
                style={{
                  margin: 0,
                  fontSize: "7vw",
                  color: "#554344",
                  fontFamily: "AIAEverestCondensedMedium",
                  paddingBottom: "30px",
                }}
              >
                CONFIRM DETAILS
              </p>
              <p style={fontSizeKh}>
                សូមពិនិត្យមើលនិងផ្ទៀងផ្ទាត់ព័ត៌មានខាងក្រោម
              </p>
              <p style={fontSizeEN}>
                Please review and confirm the information below.
              </p>
              <div
                style={{
                  background: "#FFFFFF 0% 0% no-repeat padding-box",
                  boxShadow: "0px 2px 5px #0000002E",
                  borderRadius: "10px",
                  marginTop: "30px",
                  width: "auto",
                  padding: "15px",
                }}
              >
                <Row style={spacingRow}>
                  <Col span={12}>
                    <p style={fontSizeKh}>ឈ្មោះអ្នកត្រូវបានធានា</p>
                    <p style={fontSizeEN}>Insured Name</p>
                  </Col>
                  <Col span={12} style={{ ...fontSizeBoldEN, textAlign: "right" }}>
                    <p style={fontSizeEN}> &nbsp;</p>
                    {data.PolicyInformation.ClientName}
                  </Col>
                </Row>
                <Row style={spacingRow}>
                  <Col span={12}>
                    <p style={fontSizeKh}>លេខបណ្ណសន្យារ៉ាប់រង</p>
                    <p style={fontSizeEN}>Policy Number</p>
                  </Col>
                  <Col span={12} style={{ ...fontSizeBoldEN, textAlign: "right" }}>
                    <p style={fontSizeEN}> &nbsp;</p>
                    {data.PolicyInformation.Policy}
                  </Col>
                </Row>
                <Row style={spacingRow}>
                  <Col span={12}>
                    <p style={fontSizeKh}>ចំនួនទឹកប្រាក់បុព្វលាភ</p>
                    <p style={fontSizeEN}>Premium Amount</p>
                  </Col>
                  <Col span={12} style={{ ...fontSizeBoldEN, textAlign: "right" }}>
                    <p style={fontSizeEN}> &nbsp;</p>
                    US$ {data.PolicyInformation.Amount}
                  </Col>
                </Row>
                <Row style={spacingRow}>
                  <Col span={12}>
                    <p style={fontSizeKh}>កាលបរិច្ឆទបង់បុព្វលាភ</p>
                    <p style={fontSizeEN}>Premium Due Date</p>
                  </Col>
                  <Col span={12} style={{ ...fontSizeBoldEN, textAlign: "right" }}>
                    <p style={fontSizeEN}> &nbsp;</p>
                    {moment(data.PolicyInformation.PaymentDueDate).format("DD MMMM YYYY")}
                  </Col>
                </Row>
              </div>
              <Button
                type="primary"
                block
                htmlType="submit"
                onClick={ConfrimBtn}
                style={BtnSubmit}
              >
                <span style={{ fontFamily: "HANUMANB" }}>បញ្ជាក់</span> <br />{" "}
                CONFIRM
              </Button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Detail;
