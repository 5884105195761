import React, { Component } from "react";
import { Row, Col, Modal, Button  } from "antd";
import html2canvas from 'html2canvas';
import jsPDF from "jspdf";
class SucessPage extends Component {
  constructor() {
    super();
    this.state = {
      isModalVisible: false,
      contactUsPhoneNumber: [],
    };
  }
  render() {
    const fontSizeEN = {
      fontFamily: "AIAEverestRegular",
      fontSize: "15px",
      color: "#554344",
      spacing: 0,
      margin: 0,
      letterSpacing: "-0.1px",
    };

    const fontSizeKh = {
      fontFamily: "HANUMAN",
      fontSize: "15px",
      color: "#554344",
      margin: 0
    };

    const fontSizeBoldEN = {
      fontFamily: "AIAEverestCondensedMedium",
      fontSize: "5vw",
      color: "#554344",
      spacing: 0,
      margin: 0,
    };

    const fontSizeBoldKh = {
      fontFamily: "HANUMANB",
      fontSize: "4vw",
      color: "#554344",
      margin: 0,
    };

    const spacingRow = {
      margin: "0px",
      paddingTop: "10px"
    };

    const iconFont = {
      justifyContent: "center",
      display: "flex",
      fontFamily: "AIAEverestRegular",
      color: "#554344",
    };

    const contactUsFontKh = {
      fontFamily: "HANUMANB",
      fontSize: "15px",
      color: "#554344",
      margin: 0,
    };

    const contactUsFontEn = {
      fontFamily: "AIAEverestMedium",
      fontSize: "15px",
      color: "#554344",
      padding:0,
      margin:0,
      textAlign: 'left'
    };

    // Screenshot Function
    const onScreenshotBtn = () => {
        html2canvas(document.body)
        .then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            var tmpLink = document.createElement( 'a' );  
            tmpLink.download = `${this.props.location.state.data.Policy+'_payment.png'}`;
            tmpLink.href = imgData;  
            document.body.appendChild( tmpLink );  
            tmpLink.click();  
            document.body.removeChild( tmpLink );  
        });
    };

    // Generate PDF
    const onPdfDownloadBtn = () => {
      html2canvas(document.body)
        .then((canvas) => {
                var myImage = canvas.toDataURL("image/png,1.0");
                // jspdf changes
                var pdf = new jsPDF('p');
                // Adjust width and height
                pdf.internal.pageSize.width = "375"
                pdf.internal.pageSize.height = "667"
                var width = pdf.internal.pageSize.getWidth();
                var height = pdf.internal.pageSize.getHeight();

                pdf.addImage(myImage, 'png', 0, 0, width, height);
                pdf.save(`${this.props.location.state.data.Policy}_payment.pdf`);
        });
    };

    const openTelegram = () => {
      return window.open(this.props.location.state.telegramBotURL, "_blank");
    }
    const showModal = () => {
      this.setState({ isModalVisible: true });
    };

    return (
      <React.Fragment>
        <div style={{ width: "100%", justifyContent: "center" }}  id="content">
          <div style={{ width: "auto", padding: "30px" }}>
            <div style={{ textAlign: "justify", position: "relative" }}>
              <p
                style={{
                  margin: 0,
                  fontSize: "6vw",
                  color: "#554344",
                  fontFamily: "HANUMANB",
                }}
              >
                សូមអរគុណ
              </p>
              <p
                style={{
                  margin: 0,
                  fontSize: "7vw",
                  color: "#554344",
                  fontFamily: "AIAEverestCondensedMedium",
                  paddingBottom: "10px",
                }}
              >
                THANK YOU
              </p>
              <p style={fontSizeBoldKh}>សូមអបអរសាទរ</p>
              <p style={fontSizeBoldEN}>Congratulations</p>
              <div
                style={{
                  marginTop: "20px",
                  width: "auto",
                }}
              >
                <p style={fontSizeKh}>
                  លោកអ្នកបានបន្តសុពលភាពបណ្ណសន្យារ៉ាប់រងអេអាយអេដោយជោគជ័យ
                </p>
                <p style={fontSizeEN}>
                  You have successfully renewed your AIA Insurance Policy.
                </p>
              </div>
              <div
                style={{
                  width: "auto",
                  paddingTop: "20px",
                }}
              >
                <Row style={spacingRow}>
                  <Col span={12}>
                    <p style={fontSizeKh}>លេខបណ្ណសន្យារ៉ាប់រង</p>
                    <p style={fontSizeEN}>Policy Number</p>
                  </Col>
                  <Col span={12} style={{ ...fontSizeEN, textAlign: "right" }}>
                    <p style={fontSizeKh}> &nbsp;</p>
                    <b>{this.props.location.state.data.Policy}</b>
                  </Col>
                </Row>
                <Row style={spacingRow}>
                  <Col span={12}>
                    <p style={fontSizeKh}>ថ្ងៃបង់ប្រាក់</p>
                    <p style={fontSizeEN}>Payment Date</p>
                  </Col>
                  <Col span={12} style={{ ...fontSizeEN, textAlign: "right" }}>
                    <p style={fontSizeKh}> &nbsp;</p>
                    <b>{new Date().toISOString("dd MMMM yyyy").slice(0, 10)}</b>
                  </Col>
                </Row>
              </div>
              <div
                style={{
                  paddingTop: "20px",
                  justifyContent: "right",
                }}
              >
                <Row>
                    <Col span={6}/>
                    <Col span={6}/>
                  <Col span={6}>
                    <div
                      style={{
                        margin: "0",
                        justifyContent: "center",
                        display: "flex",
                        padding: 0,
                      }}
                      onClick= {onScreenshotBtn}
                    >
                      <img
                        src="Screenshot_Icon.svg"
                        height="40px"
                        width="40px"
                      />
                    </div>
                    <p style={iconFont}>Screenshot</p>
                  </Col>
                  <Col span={6}>
                    <div
                      style={{
                        justifyContent: "center",
                        display: "flex",
                      }}
                      onClick = {onPdfDownloadBtn}
                    >
                      <img src="Download_Icon.svg" height="40px" width="40px" />
                    </div>
                    <p style={iconFont}>Download</p>
                  </Col>
                </Row>
              </div>
              <div>
                <div
                    style={{
                        background: "#FFFFFF 0% 0% no-repeat padding-box",
                        boxShadow: "0px 2px 5px #0000002E",
                        borderRadius: "10px",
                        marginTop: "10px",
                        width: "auto",
                        padding: "10px",
                        alignItems:"center"
                    }}
                >
                <Row align="middle" onClick={openTelegram}>
                  <Col span={6}>
                    <div
                      style={{
                        margin: "0",
                        justifyContent: "left",
                        display: "flex",
                        padding: 0,
                        alignItems: "left"
                      }}
                    >
                      <img
                        src="telegram-logo.png"
                        height="55px"
                        width="55px"
                      />
                    </div>
                  </Col>
                  <Col span={18}>
                    <p style={contactUsFontKh}>ចុះឈ្មោះតេឡេក្រាមរបស់យើងខ្ញុំ</p>
                    <p style={contactUsFontEn}>Register For Our Telegram Service Channel</p>
                  </Col>
                </Row>
                </div>
                <div
                    style={{
                        background: "#FFFFFF 0% 0% no-repeat padding-box",
                        boxShadow: "0px 2px 5px #0000002E",
                        borderRadius: "10px",
                        marginTop: "20px",
                        width: "auto",
                        padding: "10px",
                        alignItems:"center"
                    }}
                >
                <Row align="middle" onClick={showModal}>
                  <Col span={6}>
                    <div
                      style={{
                        margin: "0",
                        justifyContent: "left",
                        display: "flex",
                        padding: 0
                      }}
                    >
                      <img
                        src="callnow-prime1.png"
                        height="55px"
                        width="55px"
                      />
                    </div>
                  </Col>
                  <Col span={18}>
                    <p style={contactUsFontKh}>ទូរស័ព្ទមកយើងខ្ញុំ</p>
                    <p style={contactUsFontEn}>Talk To Us</p>
                    <Modal
                        visible={this.state.isModalVisible}
                        footer={null}
                        closable={false}
                        centered={true}
                        style={{
                          padding:"20px"
                        }}
                      >
                        <p
                          style={{
                            fontFamily: "HANUMANB",
                            fontSize: "14px",
                            color: "#554344",
                            margin: 0,
                            marginTop: "10px",
                          }}
                        >
                          លេខទំនាក់ទំនង
                        </p>
                        <p
                          style={{
                            fontFamily: "AIAEverestMedium",
                            fontSize: "14px",
                            color: "#554344",
                            margin: 0,
                            marginBottom: "10px"
                          }}
                        >
                          Hotline
                        </p>
                        {this.props.location.state.phoneNumber.map((item) => {
                          return (
                            <Button block href={"tel:" + item} size="large"
                            style={{
                              fontFamily: "AIAEverestMedium",
                              fontSize: "3.5vw",
                              color: "#554344",
                              fontWeight: "bold",
                              marginTop: "10px",
                              marginBottom: "10px",
                              borderColor:"#B3B1A8",
                              height:"40px",
                              display: "flex",
                              alignItems: "left",
                              justifyItems:"center",
                            }}
                            >
                              <span>{item}</span>
                            </Button>
                          );
                        })}
                      </Modal>
                  </Col>
                </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default SucessPage;
